#root{
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.layout{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content{
  padding-top:2em;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  flex: 1;
  overflow: scroll;
}
#content{
  height: 100%;
}
#zoneAff{
  padding-top: 1em;
}
.menu1, .menu2{
  height: 100px;
  position: relative;
  z-index: 2;
}
.menu2-cont ul,.menu2-cont li, .menu2-cont select{
  display: inline-block;
  margin-right: 1em;
}
.menu2-cont ul{
  padding-left: 0;
}
.mess{
  position: fixed;
  top: 0px;
  left: 0px;
  font-size: 0.9em;
  color: var(--bleu);
  background-color: var(--bleu2);
  padding: 5px 10px;
}
.mess_vert{
  color: var(--vert);
  background-color: var(--vert2);
  opacity: 0;
}
.mess.alerte{
  left: auto;
  right: 0;
  color: var(--rouge);
  background-color: var(--rouge2);
}
.mess_court{
  animation: 2s linear showAndHide;
}
h4{
  font-size: 1.2em;
  color: var(--bleu);
}
h4:first-letter{
  text-transform: capitalize;
}
.ariane{
  display: inline-block;
  font-family: var(--typo1);
  font-size: 0.9em;
  font-weight: 600;
}
.arianeRech{
  font-family: var(--typo1);
  font-size: 0.8em;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bloc-ref{
  background-color: #DDD;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  cursor: pointer;
}
.bloc-ref-cont{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.bloc-ref-visu{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.bloc-caisse{
  background-color: #DDD;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.bloc-seul{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.caisse-cont{
  font-size: 0;
}
.sq-4{
  display: inline-block;
  width: 33.33%;
  height: 0;
  padding-bottom: 33.33%;
  background-size: cover;
  background-position: center;
}
.infosRef{
  position: absolute;
  left: 0;
  bottom: 0;
}
.ref-show{
  float: left;
  font-size: 0.7em;
  padding: 2px 5px;
  font-weight: 700;
  background-color: rgba(220,220,220,0.8);
}
.loc-show{
  float: left;
  font-size: 0.7em;
  font-weight: 500;
  padding: 2px 5px;
  color: #FFF;
  background-color: rgba(0,0,0,0.7);
}
.barre-btns{
  position: absolute;
  top: 0;
  right: 0;
}
.hideScrollBar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.hideScrollBar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.logo{
  display: block;
  margin-left: var(--bs-gutter-x,0.75rem);
  width: 118px;
  height: 41px;
  background-image: url(assets/img/logo_erb.svg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0px;
  bottom: 15px;
  z-index: 2;
}
.connState{
  position: absolute;
  top: 5px;
  right: 5px;
}
.menu1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.picto_menu{
  width: 65px;
  height: 65px;
  overflow: hidden;
  background-image: url(assets/img/pictos_menu.svg);
  background-size: 1100px 120px;
}
.picto_menu.active{
  background-position-y:-60px;
}
.picto_menu.disabled{
  opacity: 0.2;
}
.picto_menu>a{
  display: block;
  width: 100%;
  height: 100%;
}
.picto_menu.references{
  background-position-x: 0px;
}
.picto_menu.caisses{
  background-position-x: -65px;
}
.picto_menu.archives{
  background-position-x: -130px;
}
.picto_menu.parametres{
  background-position-x: -195px;
}
.picto_menu.compte{
  background-position-x: -260px;
}
.menu2-cont{
  position: relative;
}
.menu2{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 5px;
}
.picto_ssmenu{
  width: 55px;
  height: 55px;
  display: inline-block;
  overflow: hidden;
  background-image: url(assets/img/pictos_ssmenu.svg);
  background-size: 1100px 110px;
  cursor: pointer;
  transform-origin: bottom;
  transform: scale(0.9);
}
.picto_ssmenu:not(:first-child){
  margin-left: 0px;
}
.picto_ssmenu.active{
  background-position-y:-55px;
}
.picto_ssmenu>a{
  display: block;
  width: 100%;
  height: 100%;
}
.picto_ssmenu.refAll{
  background-position-x: -715px;
}
.picto_ssmenu.qrcode{
  background-position-x: -770px;
}
.picto_ssmenu.qrcode.fixed{
  opacity: 0.3;
  position: fixed;
  right: 0;
  bottom: 0;
}
.picto_ssmenu.refAjout{
  background-position-x: 0px;
}
.picto_ssmenu.refValidees{
  background-position-x: -55px;
}
.picto_ssmenu.synchro{
  background-position-x: -165px;
}
.picto_ssmenu.search{
  background-position-x: -220px;
}
.picto_ssmenu.select{
  background-position-x: -275px;
  width: 49px;
}
.picto_ssmenu.actions{
  background-position-x: -330px;
  width: 47px;
}
.picto_ssmenu.parametres{
  background-position-x: -385px;
}
.picto_ssmenu.users{
  background-position-x: -440px;
}
.picto_ssmenu.lieux{
  background-position-x: -495px;
}
.picto_ssmenu.update{
  background-position-x: -550px;
}
.picto_ssmenu.logs{
  background-position-x: -605px;
}
.picto_ssmenu.login{
  background-position-x: -660px;
}
.picto_ssmenu.ajoutCaisse{
  background-position-x: -110px;
}
.picto_navig{
  width: 40px;
  height: 40px;
  display: block;
  overflow: hidden;
  background-image: url(assets/img/pictos_navig.svg);
  background-size: 1100px 40px;
  cursor: pointer;
}
.picto_navig.close{
  background-position-x: -200px;
}
.picto_navig.closeBlc{
  background-position-x: -160px;
}
.picto_navig.left1{
  background-position-x: 0px;
}
.picto_navig.right1{
  background-position-x: -40px;
}
.navRef{
  width: 60px;
  padding: 20px 10px;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;
  display: flex;
  align-items: center;
}
.closeOver{
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 2;
}
.qreader-zone {
  background-color: #000;
}
.qreader-zone, .qreader-zone > section, .qreader-zone > section > div{
  width: 100%;
  height: 100%;
  padding-top: inherit!important;
}
.qreader-infos{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  min-height: 100px;
  color: #000;
  background-color: rgba(255,255,255,1);
  z-index: 2;
  padding: 20px;
}
.qreader-btn{
  position:fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}
.debug{
  position: absolute;
  padding: 10px;
  background-color: red;
  color: #FFF;
}
.message{
  color: green;
  font-style: italic;
  line-height: 1em;;
  white-space: pre-line;
}
.zoneMess{
  margin: 1em 0;
  padding: 1em;
  background-color: #DDD;
}
.zoneMess textarea{
  border: none;
  font-size: 0.9em;
}
.picto_admin{
  width: 25px;
  height: 25px;
  display: block;
  overflow: hidden;
  background-image: url(assets/img/pictos_admin.svg);
  background-size: 1100px 25px;
  cursor: pointer;
}
.picto_admin.spec{
  display: inline-block;
  transform-origin: bottom left;
  transform: scale(0.7);
}
.picto_admin.spec2{
  transform: scale(1);
  zoom: 0.8;
}
.picto_admin.download{
  background-position-x: -0px;
}
.picto_admin.edit{
  background-position-x: -25px;
}
.picto_admin.trash{
  background-position-x: -50px;
}
.picto_admin.plus{
  background-position-x: -100px;
}
.picto_admin.plus_spec1{
  position: absolute;
  right: 5px;
}
.picto_admin.plus_spec2{
  transform: rotate(45deg);
}
.picto_admin.sup{
  background-position-x: -125px;
}
.picto_admin.up{
  display: inline-block;
  background-position-x: -200px;
}
.picto_admin.left{
  display: inline-block;
  background-position-x: -250px;
}
.picto_admin.right{
  display: inline-block;
  background-position-x: -275px;
}
.picto_admin.down{
  display: inline-block;
  background-position-x: -225px;
}
.picto_admin.rot{
  display: inline-block;
  background-position-x: -300px;
}
.picto_admin.edit{
  background-position-x: -25px;
}
.picto_admin.offline{
  display: inline-block;
  background-position-x: -325px;
}
.picto_admin.offline.fixed{
  position: fixed;
  right: 5px;
  top: 0px;
}
.picto_admin.downloadCircle{
  display: inline-block;
  background-position-x: -350px;
}
.picto_admin.downloadCircle.spec1{
  zoom:1.3;
}
.picto_admin.exportpdf{
  display: inline-block;
  background-position-x: -375px;
}
.picto_admin.exportpdf.spec1{
  zoom:1.3;
}
.picto_icone{
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
  background-image: url(assets/img/pictos_icones.svg);
  background-size: 1100px 30px;
  cursor: pointer;
}
.picto_icone.spec1{
  position: absolute;
  top: 5px;
  left: 5px;
}
.picto_icone.select{
  background-position-x: -240px;
}
.picto_icone.oui{
  background-position-x: -90px;
}
.picto_icone.non{
  background-position-x: -120px;
}
.picto_icone.rem{
  background-position-x: -570px;
}
.picto_icone.attente{
  background-position-x: -180px;
}
.zoneParam{
  margin-top: 2em;
  margin-bottom: 1em;
}
.itemParam{
  padding: 0.3em 0;
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer;
}
.itemParam:first-child{
  border-top: 1px solid #dbdbdb;
}
.itemParam:hover{
  background-color: #fafafa;
}
.addZone{
  position: fixed;
  top: 104px;
  right: 10px;
  background-color: #FFF;
  padding: 1em;
  z-index: 10;
  user-select: none;
}
.itemLog{
  padding: 0.2em 0;
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
}
.itemLog:first-child{
  border-top: 1px solid #dbdbdb;
}
.barre_save{
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 12;
  height: 100px;
  width: 100%;
  background-color: #FFF;
  padding-top: 20px;
}
.barre_save .container{
  padding: 0;
}
.bloc_plus{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.bloc_plus .texte{
  font-size: 0.8em;
  margin-left: 4px;
}
form label{
  font-weight: 600;
}
form input{
  border: 1px solid #CCC;
}
.form input, .form select{
  height: 30px;
}
.form label{
  font-weight: 600;
  width: 100%;
}
.upZone label{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mt-05{
  margin-top: 0.5em!important;
}
.mt-1{
  margin-top: 1em!important;
}
.mb-05{
  margin-bottom: 0.5em!important;
}
.mb-1{
  margin-bottom: 1em!important;
}
.ml-05{
  margin-left: 0.5em!important;
}
.ml-1{
  margin-left: 1em!important;
}
.ml-2{
  margin-left: 2em!important;
}
.mr-2{
  margin-right: 0.5rem!important;
}
.mr-3{
  margin-right: 0.75rem!important;
}
.mr-4{
  margin-right: 1rem!important;
}
.h100{
  height: 100%;
}
.h0{
  height: 0px;
}
.tar{
  text-align: right;
}
.tac{
  text-align: center;
}
.mgr1{
  margin-right: 1em;
}
.mgt1{
  margin-top: 1em;
}
.mg0{
  margin: 0;
}
.pd0{
  padding: 0;
}
.w100{
  width: 100%;
}
.sc07{
  transform: scale(0.7);
}
.sc08{
  transform: scale(0.8);
}
.fs08{
  font-size: 0.8em;
}
.w-fit-content{
  width: fit-content;
}
.text-right{
  text-align: right;
}
.urlNaked{
  text-decoration: none;
  color: inherit;
}
.urlNaked:hover, .urlNaked:visited{
  color: inherit;
}
.typo1{
  font-family: var(--typo1);
}
.coul1{
  color: #000;
}
.bg-offline{
  background-color: #EEE;
}
.bloc_img{
  position: relative;
  height: 200px;
  background-color: #EEE;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
.bloc_img .infos{
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 0.7em;
  color: #666;
}
.bloc_view{
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bloc_view img{
  max-width: 100%;
  max-height: 100%;
}
.bloc_img .pictos_admin{
  position: absolute;
  bottom: 10px;
  height: 25px;
  padding: 0 10px;
  width: 100%;
}
.bloc_img .pictos_admin .picto_admin{
  position: relative;
  display: inline-block;
}

.bloc_img .picto_admin.sup{
  float: right;
}
.bloc_img .picto_admin.left, .bloc_img .picto_admin.right{
  margin-right: 0.5em;
}
.btn1{
  position: absolute;
  top: -27px;
  right: 0;
  font-size: 0.8em;
}
.btn-spec1{
  display: inline-block!important;
  width: 100%!important;
  max-width: 250px;
}
.selectZone{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50,100,255,0.3);
    border: 2px solid var(--bleu);
}
.selectZone::before{
  content: "";
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-image: url(assets/img/pictos_icones.svg);
  background-size: 1100px 30px;
  background-position-x: -240px;
}
.pictoZone{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.pictoZone::before{
  content: "";
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 5px;
  left: 5px;
  background-image: url(assets/img/pictos_icones.svg);
  background-size: 1100px 30px;
}
.pictoZone.valideNot::before{
  background-position-x: 0px;
}
.pictoZone.valideYes::before{
  background-position-x: -30px;
}
.pictoZone.corbeille::before{
  background-position-x: -480px;
}
.pictoZone.synchroNot::before{
  background-position-x: -60px;
}
.pictoZone.statutValideOk::before{
  background-position-x: -90px;
}
.pictoZone.statutValideNo::before{
  background-position-x: -120px;
}
.pictoZone.statutValideConflit::before{
  background-position-x: -150px;
}
.itemDoc{
  padding: 10px;
  margin-bottom: 1em;
  background-color: #EEE;
}
.upZone{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdbdb;
  height: 200px;
}
.upZoneDoc,.upZoneCar{
  height: 60px;
}
.flex-bottom-right{
  display: flex;
    align-items: end;
    justify-content: end;
}
.container{
  position: relative;
}
.overZone{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.9);
  z-index: 10;
}
.container-over{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.over-fond{
  position: absolute;
  width: 100%;
  height: 100%;
}
.fiche{
  width: 90%;
  height: 90%;
  background-color: #FFF;
  position: relative;
  padding: 1em;
  overflow: scroll;
  border-radius: 0.5em;
}
.fiche-auteur{
  display: inline-block;
  height: 1.5em;
  border-bottom: 1px solid #BBB;
  font-size: 0.8em;
  text-transform: uppercase;
  margin-bottom: 1.5em;
}
.fiche .texte{
  padding-top:2em;
  padding-bottom:1em;
  padding-left: calc(var(--bs-gutter-x) * .5);
  padding-right: calc(var(--bs-gutter-x) * .5);
}
.fiche .extension{
  font-size: 0.8em;
  text-transform: uppercase;
}
.fiche .docs, .fiche .caracteristiques{
  margin-top: 2em;
}
.fiche .docs{
  margin-bottom: 2em;
}
.fiche .docs a{
  text-decoration: none;
  color: #000;
}
.fiche .docs a:visited, .fiche .docs a:hover, .fiche .docs a:focus{
  color: #000;
}
.fiche .caracteristique{
  font-size: 0.9em;
  margin-top: 0.5em;
}
.fiche .texte .infos{
  font-style: oblique;
  font-size: 0.9em;
}
.upFirst:first-letter{
  text-transform: capitalize;
}
.fiche .visuels{
  height: 100%;
  overflow: scroll;
  text-align: left;
}
.fiche .visuels .visuel:first-child{
display: block;
}
.ficheRech{
  max-width: 500px;
  max-height: 600px;
  height: fit-content;
}
.formRech-header{
  border-bottom: 1px solid #EEE;
  padding: 1em 2em 2em;
  text-align: center;
}
.formRech{
  padding: 2em;
}
.formRech label{
  font-weight: 600;
  font-size: 0.9em;
}
.formRech ::placeholder{
  font-size: 0.9em;
}
.carousel {
  position: relative;
  height: 100%;
  background-color: #EEE;
}
.carousel-inner {
  height: 100%;
}
.carousel-item{
  height: 100%;
}
.diapoItem{
  height: 100%;
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
}
.admin-links{
  position:absolute;
  top: 0;
  right: 0;
  padding: 4px 10px;
  z-index: 2;
  background-color: #DDD;
  display: flex;
  align-items: center;
  font-size: 0.7em;
}
.admin-links .infos, .admin-links .actions{
  display: inline-block;
}
.admin-links .infos{
  margin-right: 10px;
  text-transform: uppercase;
}
.validations{
  display: flex;
  align-items: center;
}
.zone-validation{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0,0,0,0.7);
}
.bloc-validation{
  width:50%;
  height: fit-content;
  background-color: #FFF;
  border-radius: 0.5em;
  display: flex;
  padding: 1em;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}
.btns-validation .btn{
  display: block;
  width: 100%;
}
.modal-title.h4{
  font-size: 1.2em;
}
.menu_actions{
  position: absolute;
  top: 100px;
  background-color: #FFF;
  border: 1px solid #000;
  z-index: 10;
  font-size: 0.8em;
  padding: 10px 0;
}
.menu_actions ul{
  margin: 0;
}
.menu_actions li{
  display: block;
  margin: 0;
  padding: 2px 20px;
  cursor: pointer;
}
.menu_actions .titre{
  display: block;
  margin: 0;
  padding: 0px 20px;
}
.menu_actions li:hover{
  background-color: #EEE;
}
.menu_filtrage{
  position: absolute;
  top: 0;
  font-size: 0.8em;
  text-align: right;
  width: auto;
  height: 0;
  z-index: 1;
}
.menu_filtres, .menu_ordre{
  display: inline-block;
  vertical-align: top;
  text-align: left;
  min-width: 200px;
  margin: 0;
}
.menu_filtres .label, .menu_ordre .label {
  text-align: right;
}
.menu_filtrage ul{
  display: block;
  border: 1px solid #000;
  padding: 0.5em 1em;
  line-height: 1.7em;
  background-color: #FFF;
}
.menu_filtrage ul li{
  display: block;
}
.textarea-spec{
  height: auto;
  border: none;
  background: none;
  padding: 0;
  resize: none;
}
.textarea-spec:focus-visible {
  outline: none;
}
.lds-ring-cont{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10,0,100,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-ring-cont .info{
  font-size: 1.3em;
    font-weight: 700;
    color: #FFF;
}
.lds-ring {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: calc(25% - 8px);
  margin-left: calc(25% - 8px);
  width: 100%;
  height: 100%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50%;
  height: 50%;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.synchroZonePicto{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.synchroZonePicto::before{
  content: "";
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 5px;
  left: 5px;
  background-image: url(assets/img/pictos_icones.svg);
  background-size: 1100px 30px;
}
.synchroZonePicto.succes{
  background-color: rgba(1, 199, 80, 0.3);
}
.synchroZonePicto.succes::before{
  background-position-x: -510px;
}
.synchroZonePicto.echec{
  background-color: rgba(233, 2, 2, 0.3);
}
.synchroZonePicto.echec::before{
  background-position-x: -540px;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes showAndHide {
  0% {
    opacity:1;
  }
  70% {
    opacity:1;
  }
  to {
    opacity:0;
  }
}

@media only screen and (max-width : 420px) {
  .logo{
    top: 10px;
    height: 30px;
  }
  .picto_menu {
    transform: scale(0.8);
  }
  .menu1, .barre_save{
    height: 80px;
  }
  .menu2{
    height: 120px;
  }
  .btn-sm{
    padding: 0.15rem 0.35rem;
    font-size: .775rem;
    border-radius: 0.1rem;
  }
  .bloc-validation {
    width: 90%;
  }
  .fiche{
    position: absolute;
    height: calc(100% - 80px);
    top: 60px;
  }
  .navRef{
    position: absolute;
    height: -moz-fit-content;
    height: fit-content;
    top: 0px;
  }
  .navLeft{
    left: 0px;
  }
  .navRight{
    left: 60px;
  }
  .ficheCont{
    display: block;
  }
  .ficheCont .texte{
    padding-top: 2em;
  }
  .visuels {
    max-height: 450px;
  }
  }